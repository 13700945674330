import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const activeStyleSub = {
  fontWeight: 'bold',
}

class Menu extends React.PureComponent {
  state = { collapsed: {} }

  toggleCollapse = item => {
    this.setState(state => ({
      collapsed: {
        ...state.collapsed,
        [item]: !state.collapsed[item],
      },
    }))
  }

  render() {
    const { entries, ...props } = this.props
    if (!entries) return null

    const { collapsed } = this.state

    return (
      <Wrapper {...props}>
        <NavMenu>
          <NavList>
            <Drill
              collapsed={collapsed}
              toggleCollapse={this.toggleCollapse}
              entries={Object.entries(entries)}
            />
          </NavList>
        </NavMenu>
      </Wrapper>
    )
  }
}

const Drill = ({ collapsed, toggleCollapse, parent, entries }) =>
  entries.map(entry => {
    const path = `${parent}/${entry[0]}`
    return (
      <NavItem key={entry[0]}>
        {typeof entry[1] === 'string' ? (
          <NavLink activeStyle={activeStyleSub} to={entry[1]}>
            {entry[0]}
          </NavLink>
        ) : (
          <React.Fragment key={entry[0]}>
            <Tree onClick={toggleCollapse.bind(this, path)}>
              {!collapsed[path] ? <ArrowDown /> : <ArrowRight />}
            </Tree>
            {entry[1].Index ? (
              <NavLink activeStyle={activeStyleSub} to={entry[1].Index}>
                {entry[0]}
              </NavLink>
            ) : (
              entry[0]
            )}
            {!collapsed[path] && (
              <NavList>
                <Drill
                  collapsed={collapsed}
                  toggleCollapse={toggleCollapse}
                  parent={path}
                  entries={Object.entries(entry[1]).filter(
                    e => e[0] !== 'Index'
                  )}
                />
              </NavList>
            )}
          </React.Fragment>
        )}
      </NavItem>
    )
  })

export default Menu

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 75px);
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 75px);
  }
`

const NavMenu = styled.nav`
  position: relative;
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  padding: 0.5rem 0.75rem;
  line-height: 1.35;
`

const NavList = styled.ul`
  padding: 0;
  margin: 0 0 0.5em 0.8em;
`

const NavItem = styled.li`
  margin-top: 0.5em;
  list-style: none;
`

const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Tree = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

const Arrow = styled.span`
  display: inline-block;
  width: 14px;
  height: 0;
`
const ArrowDown = styled(Arrow)`
  border-top: 8px solid #c1c1c1;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
`

const ArrowRight = styled(Arrow)`
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 8px solid #c1c1c1;
`
