// @flow
import { find, has, get, remove, ceil } from "lodash";
import moment from "moment";
import TAFFY from "taffy";

// return a timing Array from whatever we pass in on a best effort basis
// - If a field is missing return "" for it.
// - Thus, if non of the timing fields are present then return empty object {}.
// This is a TAFFY chain terminating function
TAFFY.extend("timings", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let results = [];
  let data = this.context().results;

  if (data.length > 0) {
    data.forEach(el => {
      let t = {};
      t.startTime = get(el, "startTime", "");
      t.endTime = get(el, "endTime", "");
      t.duration = get(el, "duration", "");
      t.questionID = get(el, "questionID", "");
      t.questionTitle = get(el, "questionTitle", "");
      t.type = get(el, "type", "");
      results.push(t);
    });
  }

  return results;
});

// Attempt to locate questions within the passed data array
// and return just that part of the array starting at the questions level.
// Thus if we are passed the entire survey data return just the 'questions' sub-array etc,
TAFFY.extend("cs_targetQuestions", function(data) {
  // if no records return an empty array
  if (data.length === 0) {
    return [];
  }

  // if the records have a key 'questions' then we are at the survey level
  if (has(data[0], "questions")) {
    // ok so we drill down and return the 'questions' Array
    // FIXME: this will not work for multiple respondent data in same array like for bulk processing funcs
    return data[0].questions;
  }

  // check if the records have a key of 'questionID'
  // which might be a baskets() qObj or a full questions qObj
  // either will be valid as they both carry the 'type' information of the question
  if (has(data[0], "questionID")) {
    return data;
  }

  // we fell of the world of data so return an empty qObj
  return [];
});

TAFFY.extend("qTitle", function(title) {
  let qDB = TAFFY();

  this.context({
    results: this.getDBI().query(this.context())
  });

  let t1 = this.cs_targetQuestions(this.context().results);

  qDB = TAFFY(t1);

  let f = { questionTitle: title };
  let x = qDB().filter(f);

  this.context({
    results: qDB()
      .getDBI()
      .query(x.context())
  });

  return this;
});

// Return a taffy qObj for questions of LV2D type
//
// use like this: r.survey().LV2D().get().baskets() etc.
TAFFY.extend("LV2D", function() {
  let qDB = TAFFY();

  this.context({
    results: this.getDBI().query(this.context())
  });

  let t1 = this.cs_targetQuestions(this.context().results);

  qDB = TAFFY(t1);

  let f = { type: "LV2D" };
  let x = qDB().filter(f);

  this.context({
    results: qDB()
      .getDBI()
      .query(x.context())
  });
  return this;
});

// Return a TAFFY qObj with product View Event data
TAFFY.extend("productViews", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let t1 = this.context().results.map((rec, recnum) => {
    let viewData = {};
    if (has(rec, "qResponseData.basketContent")) {
      viewData.questionID = rec.questionID;
      viewData.type = rec.type;
      viewData.questionTitle = rec.questionTitle;
      viewData.startTime = rec.startTime;
      viewData.endTime = rec.endTime;
      viewData.duration = rec.duration;
      viewData.productViewEvents = rec.qResponseData.productViewEvents;

      // now hoist the UPC to make life easier with TAFFY etc.
      viewData.productViewEvents.forEach(el => {
        el.UPC = el.userMeta.UPC;
      });

      return viewData;
    }
  });

  // return new chainable TAFFYdb with the basket contents
  return TAFFY(
    remove(t1, el => {
      return el !== null;
    })
  )();
});

TAFFY.extend("baskets", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let t1 = this.context().results.map((rec, recnum) => {
    let basketData = {};

    if (has(rec, "qResponseData.basketContent")) {
      basketData.questionID = rec.questionID;
      basketData.type = rec.type;
      basketData.questionTitle = rec.questionTitle;
      basketData.startTime = rec.startTime;
      basketData.endTime = rec.endTime;
      basketData.duration = rec.duration;
      basketData.basketContent = rec.qResponseData.basketContent;
      basketData.basketEvents = rec.qResponseData.basketEvents;

      // now hoist the UPC to make life easier with TAFFY etc.
      basketData.basketEvents.forEach(el => {
        el.UPC = el.userMeta.UPC;
      });

      // now extract the times of first purchase per unique item and add to
      // the basketContents object for that item

      // control if the time from last purchase is zero
      let firstItem = true;
      let lastPurchaseTime;
      basketData.basketContent.forEach(el => {
        let srcUPC = el.userMeta.UPC;
        let srcBasketInitialPurchaseEvent = find(
          basketData.basketEvents,
          el => {
            return el.UPC === srcUPC && el.eventName === "ProductAddedToCart";
          }
        );

        let purchaseTime = srcBasketInitialPurchaseEvent.startTime;
        el.startTime = purchaseTime;
        if (firstItem) {
          el.timeFromLastPurchase = 0;
          firstItem = false;
        } else {
          let pt = moment(purchaseTime).utc();
          let d = pt.diff(lastPurchaseTime);
          el.timeFromLastPurchase = moment.utc(d).format("HH:mm:ss.sss");
        }
        lastPurchaseTime = moment(purchaseTime).utc();
      });

      return basketData;
    }

    // no basket present (wrong qType or no purchases) so return blank qObj
    return null;
  });

  // return new chainable TAFFYdb with the basket contents
  return TAFFY(
    remove(t1, el => {
      return el !== null;
    })
  )();
});

TAFFY.extend("simpleProductViewsObj", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let result: Array<Object> = [];

  // loop the questions
  result = this.context().results.map((rec, recnum: number) => {
    // loop the contents of the productViewEvents and extract just the main fields
    // into a simpler data structure
    let views: Array<Object> = [];
    let skus = [];
    let result: Object;

    // we may be called with a qObj that:
    // - has questions with no view events
    // - has raw top level questions object which has the qResponseData sub-object containing possible view events
    // - result of .productViews() which are qObjects already inside the qResponseData sub-object and expose
    //   a productViewEvents object at their top level
    //
    // so we need to check which we have and act accordingly:
    // - no viewEvents at any level - skip, otherwise process the sub-object
    if (
      !has(rec, "productViewEvents") &&
      !has(rec, "qResponseData.productViewEvents")
    ) {
      // no view events to be found so skip this one
      return null;
    }

    // create header result properties now as we may be changing the scope of rec below
    result = {
      questionID: rec.questionID,
      questionTitle: rec.questionTitle,
      views: [],
      skus: []
    };

    // drop down into the qResponseData sub-object if needed
    if (has(rec, "qResponseData")) {
      rec = rec.qResponseData;
    }
    rec.productViewEvents.forEach(viewObj => {
      views.push({
        startTime: viewObj.startTime,
        endTime: viewObj.endTime,
        duration: viewObj.duration,
        description: viewObj.description,
        title: viewObj.title,
        UPC: viewObj.userMeta.UPC
      });

      skus.push(viewObj.userMeta.UPC);
    });

    result.views = views;
    result.skus = skus;
    return result;
  });
  // return results array without any nulls
  return remove(result, el => {
    return el !== null;
  });
});

TAFFY.extend("simpleBasketObj", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let result: Array<Object> = [];

  // loop the questions
  result = this.context().results.map((rec, recnum: number) => {
    // loop the contents of the basket and extract just the main fields
    // into a simpler data structure
    let basket: Array<Object> = [];
    let skus = [];
    let totalBasketPrice: number = 0.0;
    let totalBasketUniqueItems: number = 0;
    let result: Object;

    // we may be called with a qObj that:
    // - has questions with no baskets
    // - has raw top level questions object which has the qResponseData sub-object containing possible baskets
    // - result of .baskets() which are qObjects already inside the qResponseData sub-object and expose
    //   a simple basketContent object at their top level
    //
    // so we need to check which we have and act accordingly:
    // - no basketContent at any level - skip, otherwise process the basketContent sub-object
    if (
      get(rec, "basketContent", "none") === "none" &&
      get(rec, "qResponseData.basketContent", "none") === "none"
    ) {
      // no basket to be found so skip this one
      return null;
    }

    // create header result properties now as we may be changing the scope of rec below
    result = {
      questionID: rec.questionID,
      questionTitle: rec.questionTitle,
      basket: [],
      skus: [],
      totalBasketPrice: 0.0,
      totalBasketUniqueItems: 0
    };

    // drop down into the qResponseData sub-object if needed
    if (get(rec, "qResponseData", "none") !== "none") {
      rec = rec.qResponseData;
    }
    rec.basketContent.forEach(basketObj => {
      let unitPrice: number = parseFloat(basketObj.priceParams.basePrice);
      let qty: number = parseInt(basketObj.quantity);
      let totalPrice: number = unitPrice * qty;

      totalBasketPrice = totalBasketPrice + totalPrice;
      totalBasketUniqueItems++;

      basket.push({
        qty: ceil(qty, 2),
        description: basketObj.description,
        title: basketObj.title,
        UPC: basketObj.userMeta.UPC,
        unitPrice: ceil(unitPrice, 2),
        totalPrice: ceil(totalPrice, 2)
      });

      skus.push(basketObj.userMeta.UPC);
    });

    result.basket = basket;
    result.skus = skus;
    result.totalBasketPrice = ceil(totalBasketPrice, 2);
    result.totalBasketUniqueItems = ceil(totalBasketUniqueItems, 0);
    return result;
  });
  // return results array without any nulls
  return remove(result, el => {
    return el !== null;
  });
});
