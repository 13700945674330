// @flow
import syrup from 'src/lib/syrup'
import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import 'src/theme/asciidoctor/asciidoctor.css'
import './layout.css'
import 'src/theme/prims/brewer-light.css'

import Header from 'src/components/header'
import Sidebar from 'src/components/sidebar'
import Footer from 'src/components/footer'
import ConsoleContext from 'src/components/console/ConsoleContext'

global.syrup = syrup

type Props = {
  children: React.Node,
  frontmatter?: {
    title?: string,
    sidebar: false | string,
    meta?: Array<any>,
  },
}

type State = {
  logs: any,
}

// Layout inspired from here: https://asciidoctor-docs.netlify.com/asciidoctor.js/
class Layout extends React.PureComponent<Props, State> {
  state = {
    logs: {},
  }

  updateLog = (id: string, log: any) => {
    this.setState(state => ({
      logs: {
        ...state.logs,
        [id]: log,
      },
    }))
  }

  render() {
    const { children, frontmatter = {} } = this.props
    const { logs } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={
                (frontmatter.title ? frontmatter.title : '') +
                ' | ' +
                data.site.siteMetadata.title
              }
              meta={[
                { name: 'description', content: 'ConceptSauce survey data analysis library. Survey data is available to clients from our public API endpoint as a JSON object which is necessarily both large and complex. Syrup is designed to aid navigation and analysis of this data in real time during an active survey.' },
                { name: 'keywords', content: '' },
                ...(frontmatter.meta || []),
              ]}
            >
              <html lang="en" />
            </Helmet>
            <Header />
            <Wrapper>
              {frontmatter.sidebar && <Sidebar name={frontmatter.sidebar} />}
              <Main>
                <ConsoleContext.Provider
                  value={{ logs, updateLog: this.updateLog }}
                >
                  {children}
                </ConsoleContext.Provider>
              </Main>
            </Wrapper>
            <Footer />
          </>
        )}
      />
    )
  }
}

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`

const Main = styled.main`
  min-height: 100vh;
  margin-top: 75px;
  @media screen and (min-width: 1024px) {
    min-width: 0;
    flex: auto;
  }
`

export default Layout
