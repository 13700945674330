// @flow

// Top level Respondent Data object encapsulation

import axios from "axios";
import TAFFY from "taffy";

// async load data for a specific respondent / pageId / projectID combination
// this will result in either a single valid data object or null on error
export const fetchRespondentData = async ({
  projectID,
  pageID,
  respondentID
}: {
  projectID: string,
  pageID: string,
  respondentID: string
}) => {
  if (!projectID) throw new Error("Missing projectID argument!");
  if (!pageID) throw new Error("Missing pageID argument!");
  if (!respondentID) throw new Error("Missing respondentID argument!");

  const url =
    RespondentData.BASE_URL +
    `?projectid=${projectID}&pageid=${pageID}&respondentid=${respondentID}`;
  try {
    const response = await axios.get(url);
    return new RespondentData(response.data);
  } catch (error) {
    console.error(error);
    throw new Error(`Unable to load / process respondent data from ${url}`);
  }
};

export class RespondentData {
  static BASE_URL = "https://survey-api.conceptsauce.io";

  static setBaseURL(newBaseUrl: string) {
    RespondentData.BASE_URL = newBaseUrl;
  }

  // $FlowFixMe
  constructor(data) {
    this._rawData = data;
    this._questions = TAFFY(this._rawData.questions);
    this._survey = TAFFY(this._rawData);
  }

  _rawData = {};

  _survey = TAFFY();

  // TaffyDB object constructed from respondentData.questions[] which is the only top level nested structure.
  _questions = TAFFY();

  get rawData() {
    return this._rawData;
  }

  // the data object is said to be complete if the respondent finished the set questions
  // which is indicated by a top level duration field being present
  get isComplete(): boolean {
    return this._rawData.hasOwnProperty("duration");
  }

  get projectID(): ?string {
    return this._rawData.projectID ? this._rawData.projectID : undefined;
  }

  get pageID(): ?string {
    return this._rawData.pageID ? this._rawData.pageID : undefined;
  }

  get respondentID(): ?string {
    return this._rawData.respondentID ? this._rawData.respondentID : undefined;
  }

  survey() {
    return this._survey();
  }

  questions() {
    return this._questions();
  }
}
