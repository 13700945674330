// @flow
import React from 'react'
import styled from 'styled-components'

import { Mobile } from 'src/menu'
import Menu from 'src/components/sidebar/Menu'

class BurgerMenu extends React.PureComponent {
  state = {
    manuOpen: false,
  }

  toggleMenu = () => {
    this.setState(state => ({ menuOpen: !this.state.menuOpen }))
  }

  render() {
    const { menuOpen } = this.state
    return (
      <Wrapper>
        <Button onClick={this.toggleMenu}>
          {!menuOpen ? (
            <>
              <Row1 />
              <Row2 />
              <Row3 />
            </>
          ) : (
            <>
              <X1 />
              <X2 />
            </>
          )}
        </Button>
        {menuOpen && <StyledMenu entries={Mobile} />}
      </Wrapper>
    )
  }
}

export default BurgerMenu

const Wrapper = styled.div`
  position: relative;
  display: block;
  margin-left: auto;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const Button = styled.button`
  width: 2.75rem;
  height: 75px;
  padding: 0;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  outline: none;
`

const StyledMenu = styled(Menu)`
  position: absolute;
  top: 75px;
  right: -15px;
  width: 50vw;
  background-color: #f9f9f9;
`

const Line = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 1px;
  margin-left: -7px;
  background-color: #fff;
`

const Row1 = styled(Line)`
  margin-top: -6px;
`

const Row2 = styled(Line)`
  margin-top: -1px;
`

const Row3 = styled(Line)`
  margin-top: 4px;
`

const X1 = styled(Row1)`
  margin-left: -5px;
  transform: rotate(45deg);
  transform-origin: left top;
`

const X2 = styled(Row3)`
  margin-left: -5px;
  transform: rotate(-45deg);
  transform-origin: left bottom;
`
