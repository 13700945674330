import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { TopBar } from 'src/menu'

const activeStyleTop = {
  fontWeight: 'bold',
  color: '#fff',
  borderBottomColor: '#3a91dc',
}

const activeStyleSub = {
  fontWeight: 'bold',
}

const Navigation = () => (
  <MenuWrapper>
    {Object.entries(TopBar).map(entry => {
      const hasSubMenu = typeof entry[1] !== 'string'
      const link = hasSubMenu ? entry[1].Index : entry[1]
      return (
        <MenuItem key={entry[0]}>
          {link ? (
            <MenuLink activeStyle={activeStyleTop} to={link}>
              {entry[0]}
            </MenuLink>
          ) : (
            <MenuDropdown>{entry[0]}</MenuDropdown>
          )}
          {hasSubMenu && (
            <Dropdown>
              {Object.entries(entry[1]).map(sub =>
                sub[0] !== 'Index' ? (
                  <DropdownItem
                    key={sub[0]}
                    activeStyle={activeStyleSub}
                    to={sub[1]}
                  >
                    {sub[0]}
                  </DropdownItem>
                ) : null
              )}
            </Dropdown>
          )}
        </MenuItem>
      )
    })}
  </MenuWrapper>
)

export default Navigation

const MenuWrapper = styled.ul`
  display: inline-flex;
  height: 75px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 3px 0 0 0;
  list-style: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  display: none;
  min-width: 160px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`

const DropdownItem = styled(Link)`
  display: block;
  padding: 12px 16px;
  color: black;
  text-decoration: none;
  &:hover {
    background-color: #f1f1f1;
  }
`

const MenuItem = styled.li`
  position: relative;
  display: inline-block;
  height: 75px;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
  &:hover ${Dropdown} {
    display: block;
  }
`

const textStyle = css`
  padding: 0 5px 5px;
  color: #bababa;
  font-size: 14px;
  line-height: 75px;
  text-decoration: none;
  text-transform: uppercase;
`

const MenuLink = styled(Link)`
  border-bottom: 3px solid transparent;
  ${textStyle};
`

const MenuDropdown = styled.span`
  ${textStyle};
`
