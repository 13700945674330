// @flow
import { has, flattenDeep } from "lodash";
import moment from "moment";
import TAFFY from "taffy";

// returns an array of items describing in chronological order the main events of this respondent
//
// Example:
//
// [
// {text: "started survey at {time}"},
// {text: "start question {title} at {time}"},
// {text: "looked at product {UPC} {product description} at {time} for {duration}"}
// {text: "purchased {UPC} {product description} at {time}"}
// {text: "finished question in {duration}"}
// {text: "finished survey in {duration}"}
//]
//
// Note what items can be included in the results depends on the source qObj, for instance
// if the qObj only has basket contents then there will not be overall survey times in the
// resulting story.
TAFFY.extend("story", function() {
  this.context({
    results: this.getDBI().query(this.context())
  });

  let results = [];

  this.context().results.map((rec, recnum) => {
    console.dir(rec);
    // work out what we have in the way of input data:
    //
    // - if we have top level survey data then we need to iterate over the lower level objects
    // - if we already have one of the lower level objects (say a basketQobj) then we do not need
    //   to iterate further than the outer results.map we are already inside.
    // see if we have high level survey data
    if (has(rec, "questions")) {
      storyOfSurvey(rec, results);
    } else {
      // TODO: Change this to "type" once Pavel fixes that issue
      if (has(rec, "qResponseData") || has(rec, "type")) {
        storyOfQuestion(rec, results);
      } else {
        storyOfBasket(rec, results);
      }
    }
  });

  return flattenDeep(results);
});

function formatDuration(duration: string) {
  return moment(duration)
    .utc()
    .format("HH:mm:ss.SSS");
}

function storyOfSurvey(surveyArray, results) {
  // seem to be at the top level so generate start and finish survey items
  results.push({
    text: `${surveyArray.respondentID}: started survey at ${
      surveyArray.startTime
    }`
  });
  moment().utc();
  let surveyEndText = {
    text: `${surveyArray.respondentID}: finished survey in ${formatDuration(
      surveyArray.duration
    )}`
  };

  // now drill down into questions for the next stage
  let questionArray = surveyArray.questions;
  // and iterate over them
  questionArray.forEach(item => {
    let questionStoryResults = [];
    storyOfQuestion(item, questionStoryResults);
    results.push(questionStoryResults);
  });
  results.push(surveyEndText);
}

function storyOfQuestion(questionArray, questionStoryResults) {
  if (has(questionArray, "questionTitle")) {
    let startedText = `started ${questionArray.questionTitle} at ${
      questionArray.startTime
    }`;
    let questionEndText = `finished ${
      questionArray.questionTitle
    } in ${formatDuration(questionArray.duration)}`;

    questionStoryResults.push({ text: startedText });

    if (has(questionArray, "qResponseData.basketContent")) {
      let basketObj = questionArray.qResponseData;
      let basketStoryResults = [];
      storyOfBasket(basketObj, basketStoryResults);
      questionStoryResults.push(basketStoryResults);
    }
    questionStoryResults.push({ text: questionEndText });
  }
}
function storyOfBasket(basketObj, basketStoryResults) {
  // do we have basket data available?
  if (has(basketObj, "basketContent")) {
    // loop over the basket items and add to story
    basketObj.basketContent.forEach(item => {
      basketStoryResults.push({
        text: `purchase ${item.quantity} x ${item.title}`
      });
    });
  }
}
