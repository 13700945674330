const docs = {
  'Quick Start': '/docs/',
  'Syrup parts': '/docs/syrupParts',
  Concepts: '/docs/concepts',
  API: {
    General: '/docs/api/generalNotes',
    fetchRespondentData: '/docs/api/fetchRespondentData',
    baskets: '/docs/api/baskets',
    simpleBasketObject: '/docs/api/simpleBasketObj',
    timings: '/docs/api/timings',
  },
}
// surveyData: {
//   'Overview':'docs/surveyData/overview',
//   basketContents: 'docs/surveyData/basketContents',
// },

const cookbook = {
  'Quick Start': '/cookbook/',
  'The Get function': '/cookbook/get/',
  'Accessing the RAW data object': '/cookbook/rawData',
  'Is a product in the basket?': '/cookbook/isProductInBasket/',
  'Products viewed but not purchased?':
    '/cookbook/whatProductsWereViewedButNotPurchased/',
}

export const TopBar = {
  Home: '/',
  Sandbox: '/sandbox/',
  Docs: '/docs/',
  Cookbook: '/cookbook/',
}

export const Sidebar = {
  docs,
  cookbook,
}

export const Mobile = {
  Home: '/',
  Sandbox: '/sandbox/',
  Docs: docs,
  Cookbook: {
    Index: '/cookbook/',
    ...cookbook,
  },
}
