// @flow
import React from 'react'
import styled from 'styled-components'

import Logo from 'src/components/logo'
import BurgrMenu from './BurgerMenu'
import Navigation from './Navigation'

const Header = () => (
  <Wrapper>
    <InnerWrapper>
      <Logo />
      <Navigation />
      <BurgrMenu />
    </InnerWrapper>
  </Wrapper>
)

export default Header

const Wrapper = styled.header`
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background-color: #212931;
`
const InnerWrapper = styled.div`
  display: flex;
  max-width: 100%;
  height: 75px;
  justify-content: space-between;
  padding: 0 15px;
  margin: 0 auto;
`
