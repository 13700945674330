// @flow
import React from 'react'
import styled from 'styled-components'
import syrup from 'src/lib/syrup'

import Menu from './Menu'
import { Sidebar as menu } from 'src/menu'

type Props = {|
  name: string,
|}

const Sidebar = ({ name }: Props) => (
  <Wrapper>
    <Navigation>
      <Panels>
        <Menu entries={menu[name]} />
        <Info>
          <Context className="context">
            <span>Syrup</span>
            <span>{syrup.version}</span>
          </Context>
        </Info>
      </Panels>
    </Navigation>
  </Wrapper>
)

export default Sidebar

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: auto;
  left: 0;
  display: none;
  width: 100%;
  font-size: 0.9375rem;

  @media screen and (min-width: 769px) {
    width: 16rem;
    font-size: 0.875rem;
  }

  @media screen and (min-width: 1024px) {
    position: static;
    display: block;
    flex: none;
    font-size: 0.8125rem;
  }
`

const Navigation = styled.aside`
  position: relative;
  top: 75px;
  height: calc(100vh 75px);
  background-color: #f8f8f7;

  @media screen and (min-width: 769px) {
    box-shadow: 0.5px 0 3px #c1c1c1;
  }

  @media screen and (min-width: 1024px) {
    position: sticky;
    top: 75px;
    height: calc(100vh - 75px);
    box-shadow: none;
  }
`

const Panels = styled.div`
  display: flex;
  height: inherit;
  flex-direction: column;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const Context = styled.div`
  display: flex;
  height: 2.5rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  background-color: #fafafa;
  box-shadow: 0 -1px 0 #e1e1e1;
  color: #5d5d5d;
  cursor: pointer;
  font-size: 0.8125rem;
  line-height: 1;

  @media screen and (min-width: 1024px) {
    font-size: 0.75rem;
  }
`
