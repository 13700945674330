import React from 'react'
import styled from 'styled-components'

const Logo = props => (
  <Wrapper {...props}>
    <a href="https://conceptsauce.io/en" rel="home">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 54.37">
        <path
          fill="#fff"
          d="M151.87,158.93a28.1,28.1,0,0,1,6-1.28c0.38,0,.76,0,1.13,0a19,19,0,0,1,4.11.05,29.39,29.39,0,0,1,7.56,1.89,29,29,0,0,1,6.39,3.72,17.25,17.25,0,0,1,2,1.83,0.84,0.84,0,0,1,.09.86l-4.05,4a0.77,0.77,0,0,1-1-.19,18,18,0,0,0-3.08-2.36,20,20,0,0,0-3.21-1.61c-0.34-.14-0.69-0.24-1-0.38-0.92-.31-1.87-0.51-2.8-0.75a18.86,18.86,0,0,0-5.27-.2c-0.64,0-1.25.16-1.89,0.2-0.87.22-1.76,0.37-2.6,0.67a11.59,11.59,0,0,0-1.62.6,20.15,20.15,0,0,0-9.38,7.92l-0.36.57c-0.2.41-.44,0.79-0.66,1.2a19.52,19.52,0,0,0-.88,2.14,13,13,0,0,0-.73,2.55,2,2,0,0,0-.14.79,1.79,1.79,0,0,0-.13.79c-0.25.91-.06,1.86-0.23,2.78a19.74,19.74,0,0,1,.12,2.33c0.23,0.38,0,.85.25,1.24a2.06,2.06,0,0,0,.14.76,2.77,2.77,0,0,0,.13.63c0.06,0.29.14,0.57,0.22,0.85a20.61,20.61,0,0,0,11.76,12.92l0.53,0.24c0.39,0.1.75,0.28,1.14,0.37,0.63,0.23,1.3.34,1.95,0.52a19.34,19.34,0,0,0,10.93-.82l0.67-.21,0.51-.25a19.18,19.18,0,0,0,3.34-1.82,22.71,22.71,0,0,0,2.47-2,1,1,0,0,1,.75-0.19c1.26,1.17,2.42,2.44,3.66,3.63a1.23,1.23,0,0,1,.54,1.09,3.5,3.5,0,0,1-1.23,1.38,27.27,27.27,0,0,1-6.31,4c-0.35.17-.72,0.31-1.08,0.46a4.52,4.52,0,0,1-.54.21c-0.64.24-1.29,0.48-2,.67a24,24,0,0,1-4.73.92,22.71,22.71,0,0,1-4.19.17,15.9,15.9,0,0,1-2.54-.23,29.36,29.36,0,0,1-5.71-1.43c-0.32-.14-0.65-0.25-1-0.39l-0.33-.16a12.31,12.31,0,0,1-1.7-.79l-0.31-.15a27.54,27.54,0,0,1-9.89-9c-0.25-.41-0.52-0.8-0.75-1.22-0.4-.67-0.74-1.37-1.1-2.06a25.39,25.39,0,0,1-2.17-6.89l-0.15-.25a1.84,1.84,0,0,0-.13-1c0-.74-0.19-1.45-0.13-2.19a38,38,0,0,1,.11-4.35c0-.84.23-1.65,0.34-2.48a26.24,26.24,0,0,1,2.28-6.58,25.77,25.77,0,0,1,5.41-7.45,27.16,27.16,0,0,1,7-5A5.56,5.56,0,0,0,149,160c0.42-.16.8-0.4,1.24-0.52a9.52,9.52,0,0,1,.93-0.37Z"
          transform="translate(-133.28 -157.52)"
        />
        <path
          fill="#2a9eff"
          d="M157.14,167.47a17.56,17.56,0,0,1,15.05,4.27,0.85,0.85,0,0,1,0,1.21c-1.09,1.1-2.18,2.18-3.27,3.28a1,1,0,0,1-1.39,0,11.32,11.32,0,0,0-7.48-2.66,12.16,12.16,0,0,0-4.22,1,11.15,11.15,0,0,0-5.87,6.26,0.5,0.5,0,0,0,.5.68h26.37a0.91,0.91,0,0,1,.82.36,18.78,18.78,0,0,1,.21,4.29A17.55,17.55,0,0,1,157.15,202a17.39,17.39,0,0,1-8.59-4.28,0.79,0.79,0,0,1,0-1.21c1-1.08,2.13-2.13,3.19-3.2a1.24,1.24,0,0,1,1-.45,11.24,11.24,0,0,0,7.21,3,11.08,11.08,0,0,0,11-7.89H143.83a0.92,0.92,0,0,1-.84-0.54,5.43,5.43,0,0,0-.1-1.23,18.25,18.25,0,0,1,.43-5.61,17.59,17.59,0,0,1,4.5-8.09A17.41,17.41,0,0,1,157.14,167.47Z"
          transform="translate(-133.28 -157.52)"
        />
      </svg>
      Concept<span>Sauce</span>
    </a>
  </Wrapper>
)

export default Logo

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3a91dc;
    font-size: ${({ size }) => (size === 'big' ? '34px' : '28px')};
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;

    svg {
      width: ${({ size }) => (size === 'big' ? '40px' : '34px')};
      height: auto;
      margin-right: 10px;
      vertical-align: top;
    }

    span {
      color: #ffffff;
    }
  }
`
